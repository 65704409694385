var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resourcebrowsing study-active-introduce"},[_c('ActivityLinkInfo',{attrs:{"activityInfoData":_vm.activityInfoData,"infoData":_vm.resourceData}}),_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"title-box"},[_vm._v("资源")]),(_vm.resourceData.implement_type == '1')?_c('div',[(_vm.resourceData.resource_list != null)?_c('div',_vm._l((_vm.resourceData.resource_list),function(item,index){return _c('div',{key:index,staticClass:"cursor six fsbc"},[_c('div',{staticClass:"fc"},[_c('img',{staticClass:"images",attrs:{"src":_vm._f("showicon")(item.resource_url)}}),(
                _vm.resourceData.status == 2 &&
                _vm.$store.state.activity_isJoin &&
                _vm.$store.state.activity_isEnable
              )?_c('new-viewonline',{attrs:{"viewSrc":item.view_url,"teaching_studio_user_resource_id":item.teaching_studio_user_resource_id,"resourceId":item.r_resource_id,"fileExt":item.mime || item.ext,"itemData":item,"fileTitle":item.resource_name,"fileSize":item.size,"fileTime":item.create_time,"fileNav":"教研活动 > 活动详情 > 查看"},on:{"browseFinish":_vm.browseFinish}},[_c('p',{staticClass:"six_word"},[_vm._v(_vm._s(item.resource_name))])]):_c('p',{staticClass:"six_word"},[_vm._v(_vm._s(item.resource_name))])],1),_c('div',{staticClass:"fc"},[(_vm.resourceData.status == 2 && _vm.$store.state.activity_isJoin)?_c('div',{staticClass:"six_one cursor",on:{"click":function($event){$event.stopPropagation();return _vm.handleFinish(item)}}},[_c('el-link',{attrs:{"target":"_blank","underline":false}},[_c('p',{staticClass:"dow_two"},[_vm._v("下载")])])],1):_vm._e(),(_vm.isShow)?_c('div',{staticClass:"cursor view_record",on:{"click":function($event){$event.stopPropagation();return _vm.browseRecords(item.id)}}},[_vm._v(" 浏览记录（"+_vm._s(item.yes.total)+" / "+_vm._s(item.yes.total + item.no.total)+"） ")]):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"title-box"},[_vm._v("评论（"+_vm._s(_vm.commentTotal)+"）")]),(_vm.$store.state.isLogin)?_c('div',{staticClass:"seven"},[_c('div',{staticClass:"seven_left"},[_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":_vm.photo_url}})]),_c('div',{staticClass:"left_word"},[_vm._v(_vm._s(_vm.username))])]),_c('div',{staticClass:"seven_right"},[_c('el-input',{attrs:{"type":"textarea","rows":6,"placeholder":"写下你的评论...","resize":"none"},model:{value:(_vm.myContent),callback:function ($$v) {_vm.myContent=$$v},expression:"myContent"}}),(
              _vm.resourceData.status == 2 &&
              _vm.$store.state.activity_isJoin &&
              _vm.$store.state.activity_isEnable
            )?_c('div',{staticClass:"release cursor",on:{"click":_vm.releaseComment}},[_vm._v(" 发布 ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"new_comment"},[_c('new-comment',{attrs:{"activity_id":_vm.activityId,"link_id":_vm.form.activity_link_resource_id,"random":_vm.random,"module_type":_vm.is_from == 1 ? 1 : 6},on:{"onTotal":_vm.onCommentTotal}})],1)]):(_vm.resourceData.implement_type == '2')?_c('UnderLineDetail',{attrs:{"is_from":+_vm.is_from,"resourceData":_vm.resourceData,"activityId":+_vm.activityId,"linkId":+_vm.form.activity_link_resource_id},on:{"getResourceData":_vm.getResourceData,"cancelLink":_vm.getInit}}):_vm._e()],1),_c('MyDialog',{attrs:{"open":_vm.open,"title":"浏览记录","width":"1120px"},on:{"onClose":function($event){_vm.open = false}}},[_c('div',{staticClass:"visit_dialog"},[_c('div',{staticClass:"pb20 fc"},_vm._l((_vm.visitArr),function(e,i){return _c('p',{key:i,staticClass:"cursor pp",class:i == _vm.form2.r_id ? 'active' : '',on:{"click":function($event){return _vm.onTriggerVisit(i)}}},[_vm._v(" "+_vm._s(e)+" ")])}),0),_c('ListOne',{attrs:{"typeVal":3,"list":_vm.previewList}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }